module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4650c6b903ff733fb822961ec3ac8a9b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Testing for accessibility","short_name":"A11y testing","start_url":"/","background_color":"#FFFFFF","theme_color":"#BBC9F7","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/apple-touch-icon.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/icon-78x78.png","sizes":"78x78","type":"image/png"},{"src":"/favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicon/maskable.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
