exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-answers-js": () => import("./../../../src/pages/answers.js" /* webpackChunkName: "component---src-pages-answers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-testing-automated-tools-js": () => import("./../../../src/pages/testing-automated-tools.js" /* webpackChunkName: "component---src-pages-testing-automated-tools-js" */),
  "component---src-pages-testing-keyboard-accessibility-js": () => import("./../../../src/pages/testing-keyboard-accessibility.js" /* webpackChunkName: "component---src-pages-testing-keyboard-accessibility-js" */),
  "component---src-pages-testing-screen-readers-js": () => import("./../../../src/pages/testing-screen-readers.js" /* webpackChunkName: "component---src-pages-testing-screen-readers-js" */),
  "component---src-pages-testing-visuals-js": () => import("./../../../src/pages/testing-visuals.js" /* webpackChunkName: "component---src-pages-testing-visuals-js" */),
  "component---src-pages-testing-zooming-js": () => import("./../../../src/pages/testing-zooming.js" /* webpackChunkName: "component---src-pages-testing-zooming-js" */)
}

